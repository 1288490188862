import { ISearchIOAggregation, ISearchResponse } from '../types';

const getKtypeFromResults = (response: ISearchResponse): string[] => {
  const ktype =
    (response?.aggregateFilters?.['count.KtypNr'] as ISearchIOAggregation)?.count?.counts || {};

  return Object.keys(ktype).filter(Boolean);
};

export default getKtypeFromResults;
