import { IGeocodeSuburbResponse } from '@api-client/src/api/getGeocodeSuburb/types';
import { ResponseStatus } from '@api-client/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseGeocodeSuburbRefs } from '../types';

const queryPostcode =
  (context: Context, refs: IUseGeocodeSuburbRefs, id: string) => async (postcode: string) => {
    Logger.debug(`useGeocodeSuburb/${id}/queryPostcode`);

    try {
      refs.loading.value = true;
      refs.result.value = null;
      refs.error.value = null;

      const geocodeSuburbResponse: IGeocodeSuburbResponse =
        await context.$googlePlaces.api.getGeocodeSuburb(postcode);

      refs.result.value = geocodeSuburbResponse;

      if (geocodeSuburbResponse.status !== ResponseStatus.OK) {
        refs.error.value = new Error('No results found.');

        Logger.error(`useGeocodeSuburb/${id}/query/error`, geocodeSuburbResponse);
      }
    } catch (error) {
      refs.error.value = error as Error;

      Logger.error(`useGeocodeSuburb/${id}/query/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default queryPostcode;
