import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import queryPostcode from './queryPostcode';
import { UseGeocodeSuburbError, UseGeocodeSuburbLoading, UseGeocodeSuburbResult } from './types';

const useGeocodeSuburb = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useGeocodeSuburb/loading-${id}`);
  const result = sharedRef(null, `useGeocodeSuburb-${id}`);
  const error = sharedRef(null, `useGeocodeSuburb/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    queryPostcode: queryPostcode(context, refs, id),
    loading: computed<UseGeocodeSuburbLoading>(() => loading.value),
    result: computed<UseGeocodeSuburbResult>(() => result.value),
    error: computed<UseGeocodeSuburbError>(() => error.value),
  };
};

export default useGeocodeSuburb;
